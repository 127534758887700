import Swiper from '@iris.interactive/handcook/public/scripts/libraries/swiper-11'

export default class BlockCustomerRelatedContent {
    constructor() {
        BlockCustomerRelatedContent.buildSwiper().then()
    }

    static async buildSwiper() {
        if (document.body.classList.contains('eco-mode-active')) return

        const elements = document.querySelectorAll('[data-element="block-customer-related-content"]') ?? []
        for (const [index, element] of elements.entries()) {
            if (element.hidden) continue

            const swiperKey = `related-content-swiper-${index}`
            element.querySelector('.swiper-container').setAttribute('data-swiper', swiperKey)
            element.querySelector('.hc-slider-button-prev').classList.add(`swiper-${swiperKey}-prev`)
            element.querySelector('.hc-slider-button-next').classList.add(`swiper-${swiperKey}-next`)

            const options = {
                mousewheel: {
                    forceToAxis: true,
                },
                slidesPerView: 1,
                spaceBetween: 10,
                speed: 1000,
                navigation: {
                    nextEl: `.swiper-${swiperKey}-next`,
                    prevEl: `.swiper-${swiperKey}-prev`,
                },
                keyboard: true,
                loop: false,
                freeMode: {
                    enabled: true,
                },
                freeModeFluid: true,
                passiveListeners: false,
                watchSlidesVisibility: true,
                breakpoints: {
                    600: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    1000: {
                        slidesPerView: 3,
                        spaceBetween: 40,
                    },
                },
            }

            const items = element.querySelectorAll('.block__customer__related-content__list__item')
            if (items.length > 0) {
                const swiperWrapper = document.createElement('div')
                swiperWrapper.classList.add('swiper-wrapper')
                const parentElement = items[0].parentElement

                for (const item of items) {
                    item.classList.add('swiper-slide')
                    swiperWrapper.append(item)
                }

                parentElement.replaceChildren(swiperWrapper)
            }
            await Swiper.create(`[data-swiper="${swiperKey}"]`, options)
        }
    }
}

document.addEventListener('DOMContentLoaded', () => {
    new BlockCustomerRelatedContent()
})
